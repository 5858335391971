import React from "react";
import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";
import AOS from 'aos';

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';

export default ({data}) => {
  const page = data.wordpressPage;
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return (
    <PrimaryLayout>
      <SEO 
        title={page.acf.seo_title}
        description={page.acf.seo_description}
        keywords={page.acf.seo_keywords}
      />
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-5 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6">
                <div className="header-text-container">
                  <div data-aos="fade-up" className="vertical-center" dangerouslySetInnerHTML={{__html: page.content}} />
                </div>
              </div>
              <div className="col-md-6">
                <img
                  data-src={page.featured_media && page.featured_media.source_url}
                  className="header-image lazyload"
                  alt="Our Projects | Digital Agency Portfolio &amp; Case Studies | Portfolio | Alkye"
                  data-aos="fade-up"
                />
                {/* <Img 
                  fixed={page.featured_media.localFile.childImageSharp.fixed}
                  alt={page.acf.seo_title}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {page.acf.service_box && page.acf.service_box.map((service, index) => (
        <div className={`container-fluid ${service.section_mode}`} key={index}>
          <div className="row p-0 m-0">
            <div className="container main-container-padding-t-2 main-container-padding-b-2">
              <div className="row">
                <div className="col-md-12">
                  <div className="header-text-container">
                    <div className="vertical-center">
                      <h2 data-aos="fade-up">{service.service_title}.</h2>
                      <p data-aos="fade-up" className="pt-3 text-primary-color" dangerouslySetInnerHTML={{__html: service.service_content}} />
                      <div className="row text-primary-color pt-3">
                        {service.service_sublinks && service.service_sublinks.map((link, index) => (
                          <div className="col-md-6 pr-4" key={index}  data-aos-delay={100 * index} data-aos="fade-up">
                            <div className="border-bottom">
                              <Link
                                to={service.service_link + '#' + link.link_label.replace(/[^a-zA-Z ]/g, "").split(' ').join('-')}
                                className="services-table-link"
                              >
                                <span className="float-left">{link.link_label}</span>
                                <span className="float-right">
                                  <i className="la la-angle-right"></i>
                                </span>
                              </Link>
                            </div>
                          </div>
                        ))}                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </PrimaryLayout>
  )
}

export const query = graphql`
{
 wordpressPage(wordpress_id: {eq: 103}) {
    content
    featured_media {
      source_url
    }
    acf {
      service_box {
        section_mode
        service_content
        service_title
        service_sublinks {
          link_label
        }
        service_link
      }
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`